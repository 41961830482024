
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginTop: '20px',
        width: '100%',
        "@media (max-width: 599px)": {
            padding: '0 5px'
        }
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
    },
    formControl: {
        // margin: '1rem',
        minWidth: 120,
    },

});

export default function ContentDivider(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {props.title}
            </Typography>
            <Divider style={{ 'margin': '10px 0' }} />
        </div>
    );
}