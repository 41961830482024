import React, { useRef, useState } from 'react';
import ClientService from '../../../../services/ClientService'
import { Link } from "react-router-dom";
import {
    employmentStatus,
    gender,
    nationalIdType,
    civilStatus,
    academicLevel,
    countries,
    contactRelationships
} from "../../../../config/constants";
import Alert from '@material-ui/lab/Alert';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Divider,
    Paper,
} from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm, Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '95%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        // flexWrap: 'wrap'
    },
    marginSection: {
        marginTop: theme.spacing(2),
        alignSelf: 'left'
    },
    innerForm: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        justifyContent: 'left',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(2),
        width: '45%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputField: {
        width: '45%',
        margin: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '180px',
        alignSelf: 'flexEnd'
    },
    divider: {
        width: '100%',
        margin: '0 auto',
    },
    dividerLast: {
        width: '100%',
        margin: '0 auto',
        marginTop: '15px',
    },
    responseStatus: {
        padding: '10px'
    },
}));

export default function SignIn() {
    const service = new ClientService()
    const classes = useStyles();
    const labelRef = useRef()
    const { register, handleSubmit, control, reset } = useForm()
    const [status, setStatus] = useState("")
    const [error, setError] = useState("")
    const [userId, setUserId] = useState(null)

    const onSubmit = async data => {
        let newUser = await service.createAccount(data)
        if (newUser.status === "success") {
            setUserId(newUser.response._id)
            setStatus("success")
            reset()
        } else {
            setStatus("error")
            setError(newUser.error)
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    REGISTRO DE CLIENTE
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Typography className={classes.marginSection} gutterBottom>
                        Información general
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.innerForm}>
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="firstName"
                            label="Nombres"
                            name="firstName"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="lastName"
                            label="Apellidos"
                            name="lastName"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="DOB"
                            type="date"
                            label="Fecha de Nacimiento"
                            name="DOB"
                            InputLabelProps={{ shrink: true }}
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="placeOfBirth"
                            label="Lugar de nacimiento"
                            name="placeOfBirth"
                            autoFocus
                        />
                        <FormControl
                            className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} shrink>
                                Género
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {gender.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="gender"
                                InputLabelProps={{ shrink: true }}
                                label="Género"
                                defaultValue=""
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                Estado civil
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {civilStatus.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="civilStatus"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="Estado Civil"
                                defaultValue=""
                            />
                        </FormControl>
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="nationality"
                            label="Nacionalidad"
                            name="nationality"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="nationalId"
                            label="Número de Documento"
                            name="nationalId"
                            autoFocus
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                Tipo de documento
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {nationalIdType.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="documentType"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="Tipo de documento"
                                defaultValue=""
                            />
                        </FormControl>
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="personalReference"
                            label="Referencia personal"
                            name="personalReference"
                            autoFocus
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                Nivel academico
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {academicLevel.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="academicLevel"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="Nivel academico"
                                defaultValue=""
                            />
                        </FormControl>
                    </div>
                    <Typography className={classes.marginSection} gutterBottom>
                        Información de contacto
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.innerForm}>
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="cellphoneNumber"
                            label="Número Telefónico"
                            name="cellphoneNumber"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="address"
                            label="Dirección"
                            name="address"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="city"
                            label="Ciudad"
                            name="city"
                            autoFocus
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                País
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {countries.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="country"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="País"
                                defaultValue=""
                                required
                            />
                        </FormControl>
                    </div>
                    <Typography className={classes.marginSection} gutterBottom>
                        Contacto o Referencia
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.innerForm}>
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="contactName"
                            label="Nombre contacto"
                            name="contactName"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="contactCellphoneNumber"
                            label="Número referencia"
                            name="contactCellphoneNumber"
                            autoFocus
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                Relación
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {contactRelationships.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="contactRelationship"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="Relación"
                                defaultValue=""
                            />
                        </FormControl>
                    </div>
                    <Typography className={classes.marginSection} gutterBottom>
                        Información laboral
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.innerForm}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                ref={labelRef} htmlFor="my-input" shrink>
                                Estatus laboral
                        </InputLabel>
                            <Controller
                                as={
                                    <Select>
                                        {employmentStatus.map(option => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                inputRef={register}
                                control={control}
                                name="employmentStatus"
                                id="my-input"
                                InputLabelProps={{ shrink: true }}
                                label="Estatus laboral"
                                defaultValue=""
                            />
                        </FormControl>
                        <TextField
                            className={classes.inputField}

                            margin="normal"
                            inputRef={register}
                            required
                            id="businessName"
                            label="Empresa"
                            name="businessName"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}

                            margin="normal"
                            inputRef={register}
                            required
                            id="startDate"
                            type="date"
                            label="Fecha de inicio"
                            name="startDate"
                            InputLabelProps={{ shrink: true }}
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}

                            margin="normal"
                            inputRef={register}
                            required
                            id="businessAddress"
                            label="Dirección de la empresa"
                            name="businessAddress"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="businessPhoneNumber"
                            label="Número de télefono"
                            name="businessPhoneNumber"
                            autoFocus
                        />
                        <TextField
                            className={classes.inputField}
                            margin="normal"
                            inputRef={register}
                            required
                            id="monthlyIncome"
                            label="Ingreso mensual (USD)"
                            name="monthlyIncome"
                            autoFocus
                        />
                    </div>
                    <Divider className={classes.dividerLast} />
                    <div className={classes.responseStatus}>
                        {status === "success" ?
                            <Alert severity="success" onClose={() => { }}>
                                Usuario creado exitosamente!
                            <Link to={`/admin/client/${userId}`}>
                                    Ver cliente
                            </Link>
                            </Alert> : ""}
                        {status === "error" ?
                            <Alert severity="error" onClose={() => { }}>
                                {error}
                            </Alert> : ""}
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Registrar cliente
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}