import React, { useState, useContext, useEffect } from 'react'
import SummaryService from '../../../../../services/summaryService'
import LoanService from '../../../../../services/LoanService'
import moment from 'moment'
import {Button} from "@material-ui/core";
import { authContext } from '../../../../../contexts/AuthContext';
import SmallCard from '../../../../cards/SmallCard'
import { Divider, Paper } from '@material-ui/core';
import HeaderCountry from '../../../../cards/HomeHeader'
import DateHeader from '../../../../cards/DateHeader'
import ContentDivider from '../../../../cards/ContentDivider'
import MenuBar from '../../../../cards/HomeMenu'
import Skeleton from '@material-ui/lab/Skeleton';
import './home.scss'
import BarLineChart from './BarLineChart'
import ScrollList from './ScrollList'
import ScrollListTotal from './ScrollListTotal'
import ScrollListPayments from './ScrollListPayments'
import './dashboard.scss'


// AdminHome()
function Dashboard() {
  const summaryService = new SummaryService()
  const loanService = new LoanService()
  const { auth } = useContext(authContext);
  const [value, setValue] = useState(null)
  const [payments, setPayments] = useState({
    getPayments: true
  })
  const [data, setData] = useState({
    getLoans: true,
  })

  const [selectedDateRange, setSelectedDateRange] = React.useState({
    gtDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    ltDate: moment().format('YYYY-MM-DD')
  });

  const handleDateChange = (event) => {
    let { name, value } = event.target
    setSelectedDateRange({
      ...selectedDateRange,
      [name]: value
    })
    setPayments({ getPayments: true });
  };


  const handleChange = (event) => {
    setValue(event.currentTarget.value);
    setData({ getLoans: true })
    setPayments({ getPayments: true });
  }

  useEffect(() => {
    if (auth.data.location === 'GLOBAL') {
      setValue("VENEZUELA")
    } else {
      setValue(auth.data.location)
    }
  }, [auth.data.location])

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (data.getLoans && value !== null) {

          // const { location } = auth.data
          const res = await summaryService.getTodayStatus(value)

          return setData({
            getLoans: false,
            ...res
          })
        }
      } catch (error) {
        console.log(error)
      }

    }
    FetchData();
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (payments.getPayments && value !== null) {
          // const { location } = auth.data
          const res = await summaryService.getPaymentStatus(value, selectedDateRange.gtDate, selectedDateRange.ltDate)

          return setPayments({
            getPayments: false,
            ...res
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    FetchData();
    // eslint-disable-next-line
  }, [value, selectedDateRange])


  return (
    <div className="grid-container">
      <div className="grid-content">
        <div className="inner-component">
          <div className="flex-column">

            <HeaderCountry value={value} onChange={handleChange} title={'Resumen cobranza'} />
            <Divider style={{ 'margin': '10px 0' }} />
            <div className="row-content" >
              <SmallCard
                title="cobranza vencida"
                content={data.overdue !== undefined ? data.overdue.interest + data.overdue.principal : null}
                innerContent1={data.overdue !== undefined ? data.overdue.interest : null}
                innerContent2={data.overdue !== undefined ? data.overdue.principal : null}
                currency={true}
                count={data.overdue !== undefined ? data.overdue.loans : null}
                countText={'prestamos'}
                to={`/admin/loan-collection?country=${value}&dayRange[]=0&dayRange[]=360&UID=${moment().format('YYYY-MM-DD')}`}
              />
              <SmallCard
                title="por vencer"
                content={data.upcoming !== undefined ? data.upcoming.interest + data.upcoming.principal : null}
                innerContent1={data.upcoming !== undefined ? data.upcoming.interest : null}
                innerContent2={data.upcoming !== undefined ? data.upcoming.principal : null}
                currency={true}
                count={data.upcoming !== undefined ? data.upcoming.installments : null}
                countText={'cuotas'}
                to={`/admin/loan-collection?country=${value}&dayRange[]=-7&dayRange[]=0&UID=${moment().add(8, 'days').format('YYYY-MM-DD')}`}
              />
            </div>

            <div className="row-content" >
              <Paper className="content-padding">
                {data.today ? < MenuBar to='/admin/loan-collection' innerText={`Hoy vencen ${data.today.installments} cuotas`} /> : <Skeleton />}
                <Divider style={{ 'margin': '10px 0' }} />
                {data.today ? < MenuBar to='/admin/payments' innerText={`Se han capturado ${data.collected.total_count} pagos`} /> : <Skeleton />}
              </Paper>
              <Button
                  // className={classes.root}
                  style={{ 'margin-top': '20px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => loanService.getOpenLoanSchedulesCSV(value)}
              >
                Descargar cronograma
              </Button>
            </div>
            <ContentDivider title={"Préstamos"} />
            <div className="row-content" >
              <Paper className="graph-container" >
                <div className="graph-view">
                  {data.disbursed ? <BarLineChart height={250} chartTitle="Prestamos aprobados" data={data.disbursed} /> : <Skeleton width={300} height={280} />}
                </div>
                <div className="graph-detail-view">
                  {data.disbursedDetails ? <ScrollList data={data.disbursedDetails} listTitle='Detalle' /> : <Skeleton width={300} height={280} />}
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <div id="sidebar" className="sidebar">
        <Paper className="sidebar-container">
          <div className="sb-options">
            <a className="toggle close" href="#">×</a>
            <DateHeader dateChange={handleDateChange} gtDate={selectedDateRange.gtDate} ltDate={selectedDateRange.ltDate} />
          </div>
          <div className="sb-graph-view">
            {payments.periodCollections ? <BarLineChart height={200} chartTitle="Pagos recibidos" data={payments.periodCollections} /> : <Skeleton width={300} height={280} />}
          </div>
          <div className="sb-detail-view">
            {payments.paymentsByCat ? <ScrollListTotal total={payments.paymentsByCat.total_collected} data={payments.paymentsByCat.categories} listTitle='Pagos por categoría' /> : <Skeleton width={300} height={280} />}
          </div>
          <div className="sb-detail-view">
            {payments.collectionsDetail ? <ScrollListPayments data={payments.collectionsDetail} listTitle='Pagos por cliente' /> : <Skeleton width={300} height={280} />}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default Dashboard
