
import React from 'react';
import {
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    ResponsiveContainer,
    Bar
} from 'recharts';


export default function BarLineChart(props) {

    return (
        <React.Fragment>
            <h4 style={{ 'font-weight': '400' }}>{props.chartTitle}</h4>
            <ResponsiveContainer width={'100%'} height={props.height}>
                <ComposedChart data={props.data} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" maxBarSize={30} dataKey="monto" fill="#17a2b8bd" />
                    <Line yAxisId="right" dataKey="numero" fill="#8884d8" />
                </ComposedChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}