import React from 'react';
import { Divider } from '@material-ui/core';




export default function ScrollList(props) {

    return (
        <React.Fragment>
            <h4 style={{ 'font-weight': '400', 'margin-bottom': '13px' }}>{props.listTitle}</h4>
            <div style={{ 'overflow': 'scroll', 'height': '230px' }}>
                {props.data.map(e => {
                    return (
                        <div style={{ 'margin-top': '5px' }}>
                            {e.fullname + " - " + e.useOfFunds + " - " + e.capital + " - " + e.interest + "% - " + e.duration}
                            <Divider style={{ 'margin': '10px 0' }} />
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    );
}