import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { authContext } from "../contexts/AuthContext";
import PendingConfirmation from "../components/auth/PendingConfirmation.jsx";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const { auth } = useContext(authContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.data) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(auth.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        if (auth.role === "borrower" && auth.data.status === "PENDING") {
          return <PendingConfirmation user={auth.data} {...props} {...rest} />;
        }

        // authorised so return component
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default ProtectedRoute;
