import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
    },
    formControl: {
        margin: '0 1rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minWidth: 120,
    },
});

export default function DateHeader(props) {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField
                id="date"
                label="Desde"
                type="date"
                name='gtDate'
                value={props.gtDate}
                onChange={props.dateChange}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="date"
                label="Hasta"
                type="date"
                name='ltDate'
                value={props.ltDate}
                onChange={props.dateChange}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </FormControl>
    );
}