import React from 'react';
import { Divider } from '@material-ui/core';
import numbro from 'numbro'


export default function ScrollListTotal(props) {

    return (
        <div style={{ 'padding': '0 1rem', 'margin-top': '0.5rem' }}>
            <h3 style={{ 'font-weight': '500', 'margin-bottom': '1rem' }}>{props.listTitle}</h3>
            <div style={{ 'display': 'flex', 'flex-direction': 'row', 'justify-content': 'space-between', 'border-bottom': '1.5px solid black' }}>
                <h3 style={{ 'font-weight': '400', 'display': 'inline' }}>Total</h3>
                <h3 style={{ 'font-weight': '400', 'display': 'inline' }}>{numbro(props.total).format({
                    thousandSeparated: true,
                    mantissa: 2
                })}</h3>
            </div>
            <div style={{ 'overflow': 'scroll', 'max-height': '230px' }}>
                {props.data.map(e => {
                    return (
                        <React.Fragment>
                            <div style={{ 'margin-top': '5px', 'display': 'flex', 'flex-direction': 'row', 'justify-content': 'space-between' }}>
                                <div>{e._id}
                                </div>
                                <div>{numbro(e.total_amount).format({
                                    thousandSeparated: true,
                                    mantissa: 2
                                })}
                                </div>
                            </div>
                            <Divider style={{ 'margin': '5px 0' }} />
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    );
}