import React, { createContext, useState, useEffect, useCallback, } from "react";
import authService from "../components/auth/AuthService";
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from 'prop-types'

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [auth, setAuth] = useState({ loading: true, data: null, role: null });

  const setAuthData = (data) => {
    if (auth.data) {
      return setAuth({ data: null, role: null });
    } else {
      let role;
      if (data.admin) {
        role = "admin";
      } else if (data.collector) {
        role = "collector";
      } else if (data.borrower) {
        role = "borrower";
      }
      setAuth({ data: data, role: role });
    }
  };

  function useCheckAuthStatus(auth) {
    const fetchData = useCallback(async () => {
      try {
        if (!auth) {
          authService.loggedin().then(
              (response) => {
                setAuthData(response)
              });
        }
      } catch (error) {
        history.push("/sign-in");
      }
    }, [auth]);
    useEffect(() => {
      fetchData();
    }, [fetchData]);
  }
  useCheckAuthStatus(auth.data);

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any
}
export default withRouter(AuthProvider);
