// auth/Signup.js
import React, { useState, useContext } from "react";
import { authContext } from "../../contexts/AuthContext";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";
import AuthService from "./AuthService";
import { Button, FormControl, Input, InputLabel } from "@material-ui/core";
import "./auth.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    border: "1px solid red",
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    height: "500px",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    backgroundColor: "#0F1433",
    color: "#F2F5F7",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#011D66",
      color: "#F2F5F7",
    },
  },
}));

let initialState = { username: "", password: "", error: false };

const Login = ({ ...props }) => {
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const classes = useStyles();
  const [details, setDetails] = useState(initialState);
  const { setAuthData } = useContext(authContext);
  const service = AuthService;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const username = details.username;
    const password = details.password;
    service
      .login(username, password)
      .then((response) => {
        setDetails(initialState);
        setAuthData(response);
        return props.history.push("/");
        // if (response.admin || response.collector) {
        //   return history.replace(from.pathname === '/' ? '/admin' : from.pathname);
        // } else if (response.status === 'PENDING') {
        //   return props.history.push("/client/pending")
        // } else if (response.borrower) {
        //   return history.replace(from.pathname === '/' ? '/client' : from.pathname);
        // }
      })
      .catch((error) => {
        console.log(error);
        setDetails({
          ...details,
          error: error.response.data.message,
        });
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };

  return (
    <div className="access-holder">
      <div className="entry" />
      <div className="access-window">
        <div className="logo-access" />
        <div className="user-credentials">
          <p className="access-window-title">Bienvenido de nuevo.</p>
          <p className="access-window-subtitle">
            No tienes una cuenta? <Link to="/signup">registrate aqui!</Link>
          </p>
          <form className={classes.form} onSubmit={handleFormSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Usuario</InputLabel>
              <Input
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                value={details.username}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={details.password}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Ingresar
            </Button>
          </form>
          {details.error ? (
            <div className="error-notification">
              <h1>Usuario o contraseña incorrecta, intente nuevamente</h1>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
