import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'middle',
        margin: 0
    },
    title: {
        fontSize: '1rem',
        fontWeight: 500,
    },
    formControl: {
        // margin: '1rem',
        minWidth: 120,
    },

});

export default function HomeMenu(props) {
    const classes = useStyles();

    return (
        <Link to={props.to}>
            <div className={classes.root}>
                <Typography className={classes.title} color="textSecondary">
                    {props.innerText}
                </Typography>
                <ArrowForwardIosIcon color="primary" />
            </div>
        </Link>
    );
}