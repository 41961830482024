// auth/Signup.js
import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import AuthService from "./AuthService";
import { Button } from "@material-ui/core";
import "./auth.scss";
import { makeStyles } from '@material-ui/core/styles';
import { authContext } from '../../contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    border: "1px solid red",
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    height: "500px",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3),
    backgroundColor: "#0F1433",
    color: "#F2F5F7",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#011D66",
      color: "#F2F5F7"
    }
  }
}));

const PendingConfirmation = ({ history, ...props }) => {
  const classes = useStyles();
  let { setAuthData } = useContext(authContext)
  const [initialState, setInitialState] = useState({})
  const service = AuthService

  const handleClick = () => {
    service.resendVerification()
      .then(resp => {
        if (resp.status === 'success') {
          setInitialState({ status: 'success' })
        }
      })
      .catch(e => {
        setInitialState({ status: 'failure' })
      })
  }

  const logout = () => {
    service.logout()
      .then((res) => {
        if (res.message === 'success') {
          setAuthData(null);
          history.push('/login')
        }
      })
  }



  return (
    <div className="access-holder">
      <div className="entry" />
      <div className="access-window">
        <div className="logo-access" />
        <div className="user-credentials">
          <p className="access-window-title">Bienvenido {props.user.firstName.charAt(0).toUpperCase() + props.user.firstName.slice(1)}</p>
          <p className="access-window-subtitle">
            Para activar tu cuenta revisa tu buzon de correo electronico y sigue los pasos para la confirmación.
            </p>
          <p className="access-window-subtitle">
            Si no has recibido el correo de verificación, haz click en el siguiente botón.
            </p>
          <Button
            type="button"
            fullWidth
            onClick={() => handleClick()}
            variant="contained"
            className={classes.submit}
          >
            Reenviar correo de confirmación
              </Button>
          {initialState.status === 'success' && <div className="access-notify-success"><p> El correo de confirmación se ha reenviado correctamente </p></div>}
          {initialState.status === 'failure' && <div className="access-notify-failure"><p> El correo de confirmación no se ha enviado correctament, intente nuevamente </p></div>}
          <p className="access-window-note">*Recuerda de revisar en tu buzón de correo no deseado</p>
          <Button
            type="button"
            fullWidth
            onClick={() => logout()}
            variant="contained"
            className={classes.submit}
          >
            Cerrar Session
              </Button>
        </div>
      </div>
    </div>
  );

}


export default withRouter(PendingConfirmation);
