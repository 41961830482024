import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import numbro from "numbro";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    padding: "1.5rem",
    minWidth: "340px",
  },
  contentRoot: {
    padding: "0rem",
  },
  actionsRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0rem",
    margin: "0",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  mainContent: {
    marginTop: "0.3em",
    fontWeight: 600,
  },
  innerContent: {
    marginTop: "0.2em",
    fontWeight: 300,
    color: "#3f5bc2cc",
  },

  title: {
    fontSize: "0.7rem",
    fontWeight: 500,
  },
  divi: {
    margin: "10px 0px",
    // width: '90%'
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.contentRoot}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.title.toUpperCase()}
        </Typography>
        <Typography variant="h5" className={classes.mainContent}>
          {props.content === null ? (
            <Skeleton height={35} />
          ) : (
            numbro(props.content).formatCurrency({
              thousandSeparated: true,
              mantissa: 2,
            })
          )}
        </Typography>
        <Typography
          className={classes.innerContent}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {props.innerContent1 === null ? (
            <Skeleton height={20} />
          ) : (
            numbro(props.innerContent1).format({
              thousandSeparated: true,
              mantissa: 2,
            })
          )}
        </Typography>
        <Typography
          className={classes.innerContent}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {props.innerContent2 === null ? (
            <Skeleton height={20} />
          ) : (
            numbro(props.innerContent2).format({
              thousandSeparated: true,
              mantissa: 2,
            })
          )}
        </Typography>
      </CardContent>
      <Divider className={classes.divi} />
      <CardActions className={classes.actionsRoot}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.count === null ? (
            <Skeleton width={100} />
          ) : (
            props.count + " " + props.countText
          )}
        </Typography>
        {props.count === null ? (
          <Skeleton width={50} />
        ) : (
          <Link to={props.to}>
            <Button variant="outlined" size="small" color="primary">
              Ver detalle
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  );
}
