import React from 'react';
import { Divider } from '@material-ui/core';
import {
    Link,
} from "react-router-dom";
import numbro from 'numbro';



export default function ScrollListPayments(props) {

    return (
        <div style={{ 'padding': '0 1rem', 'margin-top': '0.5rem' }}>
            <h3 style={{ 'font-weight': '500', 'margin-bottom': '1rem' }}>{props.listTitle}</h3>
            <div style={{ 'overflow': 'scroll', 'max-height': '230px' }}>
                {props.data.map(e => {
                    return (
                        <React.Fragment>
                            <div style={{ 'margin-top': '5px', 'display': 'flex', 'flex-direction': 'row', 'justify-content': 'space-between', 'align-items': 'flex-start' }}>
                                <div style={{
                                    'width': '130px',
                                }}>
                                    <Link className='one-liner' to={`/admin/loan/${e._loan}`}>{e.fullname}</Link>
                                </div>
                                <div style={{ 'flex-grow': '1', 'flex-basis': 'auto' }}>{e.useOfFunds}
                                </div>
                                <div style={{
                                    // 'flex-grow': 1
                                }}>{numbro(e.amount).format({ thousandSeparated: true })}
                                </div>
                            </div>
                            <Divider style={{ 'margin': '5px 0' }} />
                        </React.Fragment>
                    )
                })}
            </div>
        </div >
    );
}