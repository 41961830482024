import React, { Component } from 'react'
import summaryService from '../../../../../services/summaryService'
import PortfolioCard from './PortfolioCard'
import PortfolioScheduleCard from './PortfolioScheduleCard'
import { authContext } from '../../../../../contexts/AuthContext'

export default class LoanList extends Component {
    static contextType = authContext
    state = {
        getSummary: true
    }
    summaryService = new summaryService();

    UNSAFE_componentWillMount() {

        this.fetchSummary()
    }


    fetchSummary = async () => {
        if (this.state.getSummary) {
            const { location } = this.context.auth.data
            let scheduleTotal = (location !== 'PERU' ? await this.summaryService.portfolioTotalsByEveryStatus() : null)
            let totals = await this.summaryService.totals(location)
            let monthTotals = await this.summaryService.portfolioMonthStatus()
            Promise.all([totals, scheduleTotal, monthTotals])
                // this.summaryService.totals(location)
                .then(response => {
                    this.setState({
                        getSummary: false,
                        data: response[0],
                        scheduleData: response[1],
                        monthData: response[2]
                    })

                })
                .catch(err => {
                    this.setState({
                        data: false
                    })
                })
        }
    }



    render() {

        const { data, scheduleData, monthData } = this.state

        return (
            <div className="content">
                {(data && monthData) ? data[0].map((e, i) => {
                    return <PortfolioCard key={i} data={e} monthData={monthData.filter(el => el._id === e._id)} />
                }) : ""}
                {scheduleData ? <PortfolioScheduleCard data={scheduleData} /> : ""}
            </div>
        )
    }
}