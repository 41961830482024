import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { authContext } from "../../contexts/AuthContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "@media (max-width: 599px)": {
      padding: "0 5px",
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  formControl: {
    // margin: '1rem',
    minWidth: 120,
  },
});

export default function HeaderCountry(props) {
  const classes = useStyles();
  const { auth } = useContext(authContext);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {props.title}
      </Typography>
      <FormControl className={classes.formControl}>
        {auth.data.location === "GLOBAL" ? (
          <Select
            native
            disableUnderline={true}
            className={classes.title}
            value={props.value || ""}
            name="country"
            onChange={(e) => props.onChange(e)}
            label="Age"
            inputProps={{
              name: "country",
              id: "outlined-age-native-simple",
            }}
          >
            {/* <option aria-label="None" value="" /> */}
            <option value="PERU">Peru</option>
            <option value="VENEZUELA">Venezuela</option>
            <option value="DOMINICAN_REPUBLIC">Rep. Dominicana</option>
          </Select>
        ) : (
          ""
        )}
      </FormControl>
      <a className="toggle open" href="#sidebar">
        <span className="material-icons">legend_toggle</span>
      </a>
    </div>
  );
}
