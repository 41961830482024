import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { authContext } from "../contexts/AuthContext";
import "../App.scss";
import PendingConfirmation from "../components/auth/PendingConfirmation.jsx";
import Activity from "../components/contents/Client/Activity/Activity";
import Loan from "../components/contents/Client/Loan/Loan";
import Profile from "../components/contents/Client/Profile/Profile.jsx";
require("dotenv").config();

const ClientRoutes = ({ history, ...props }) => {
  const { auth } = useContext(authContext);
  const rolesAdmitted = ["borrower"];
  return (
    <div>
      <ProtectedRoute
        exact
        path="/client/pending"
        roles={rolesAdmitted}
        user={auth.data}
        component={PendingConfirmation}
      />
      <ProtectedRoute
        exact
        path="/client"
        roles={rolesAdmitted}
        user={auth.data}
        component={Activity}
      />
      <ProtectedRoute
        exact
        path="/client/activity"
        roles={rolesAdmitted}
        user={auth.data}
        component={Activity}
      />
      <ProtectedRoute
        exact
        path="/client/loan"
        roles={rolesAdmitted}
        user={auth.data}
        component={Loan}
      />
      <ProtectedRoute
        exact
        path="/client/profile"
        roles={rolesAdmitted}
        user={auth.data}
        component={Profile}
      />
      <Redirect from="/" to="/client" />
    </div>
  );
};

export default ClientRoutes;
