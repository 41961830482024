import React, { useContext } from "react";
import AdminRoutes from "./routes/AdminRoutes.jsx";
import ClientRoutes from "./routes/ClientRoutes.jsx";
import { authContext } from "./contexts/AuthContext";
import "./App.scss";
import { Switch, Route, withRouter } from "react-router-dom";
import Signup from "./components/auth/Signup.jsx";
import Login from "./components/auth/Login.jsx";
import EmailConfirmation from "./components/auth/EmailConfirmation.jsx";
import Layout from "../src/components/layout/Layout";
require("dotenv").config();

const App = ({ history, ...props }) => {
  const { auth, setAuthData } = useContext(authContext);

  const getTheUser = (userObj) => {
    setAuthData(userObj);
  };

  if (auth.data) {
    return (
      <div className="App">
        <Layout>
          <Switch>
            <AdminRoutes />
            <ClientRoutes />
          </Switch>
        </Layout>
      </div>
    );
  } else {
    return (
      <div className="App-auth">
        <header className="App-header">
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route
              exact
              path="/signup"
              render={() => <Signup getUser={getTheUser} />}
            />
            <Route exact path="/login" render={() => <Login />} />
            <Route
              path="/confirmation/:confirmationCode"
              render={({ match }) => (
                <EmailConfirmation
                  confirmationCode={match.params.confirmationCode}
                />
              )}
            />
          </Switch>
        </header>
      </div>
    );
  }
};

export default withRouter(App);
