import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import "../App.scss";
import Portfolio from "../components/contents/Admin/Main/Portfolio/Portfolio";
import Payments from "../components/contents/Admin/Main/Payments.jsx";
import PortfolioGraphs from "../components/contents/Admin/Main/Portfolio/PortfolioGraphs";
import LoanCollection from "../components/contents/Admin/Main/Dashboard/Collection/Collection";
import Dashboard from "../components/contents/Admin/Main/Dashboard/Dashboard";
import CashAccounts from "../components/contents/Admin/Financial/CashAccounts.jsx";
// import CreateClientForm from '../components/contents/Admin/Client/CreateClientForm.jsx';
import OnboardingClient from "../components/contents/Admin/Client/OnboardingClient.jsx";
import CompanyCreateForm from "../components/contents/Admin/Company/CompanyCreateForm";
import ClientList from "../components/contents/Admin/Client/ClientList.jsx";
import ClientDetails from "../components/contents/Admin/Client/ClientDetails.jsx";
import CreateInvestor from "../components/contents/Admin/Investor/CreateInvestor";
import NewTransaction from "../components/contents/Admin/Investor/NewTransaction";
import DetailInvestor from "../components/contents/Admin/Investor/DetailInvestor.jsx";
import CreateLoan from "../components/contents/Admin/Loan/CreateLoan/CreateLoan.jsx";
import FinDashboard from "../components/contents/Admin/Financial/FinDashboard.jsx";
import PandL from "../components/contents/Admin/Financial/PandL.jsx";
import LoanList from "../components/contents/Admin/Loan/LoanList";
import LoanWeekList from "../components/contents/Admin/Loan/LoanWeekList";
import PeriodSchedule from "../components/contents/Admin/Loan/PeriodSchedule";
import Calculator from "../components/contents/Admin/Loan/Calculator/Calculator";
import LoanDetails from "../components/contents/Admin/Loan/Detail/LoanDetails";
import Collection from "../components/contents/Admin/Main/Dashboard/Collection";
import { Redirect } from "react-router-dom";

require("dotenv").config();

const AdminRoutes = ({ history, ...props }) => {
  const admin = ["admin"];
  const collector = ["admin", "collector"];
  return (
    <div>
      <ProtectedRoute
        exact
        path="/admin/investor/create"
        roles={admin}
        component={CreateInvestor}
      />
      <ProtectedRoute
        exact
        path="/admin/investor/onboard"
        roles={admin}
        component={OnboardingClient}
      />
      <ProtectedRoute
        exact
        path="/admin/investor/transaction"
        roles={admin}
        component={NewTransaction}
      />
      <ProtectedRoute
        exact
        path="/admin/investor/details"
        roles={admin}
        component={DetailInvestor}
      />
      <ProtectedRoute
        exact
        path="/admin/financials/indicators"
        roles={admin}
        component={FinDashboard}
      />
      <ProtectedRoute
        exact
        path="/admin/financials/p-and-l"
        roles={admin}
        component={PandL}
      />
      <ProtectedRoute
        exact
        path="/admin/loan/period"
        roles={admin}
        component={PeriodSchedule}
      />
      <ProtectedRoute
        exact
        path="/admin/portfolio"
        roles={admin}
        component={Portfolio}
      />
      <ProtectedRoute
        exact
        path="/admin/company/create"
        roles={admin}
        component={CompanyCreateForm}
      />
      <ProtectedRoute
        exact
        path="/admin/evolution"
        roles={admin}
        component={PortfolioGraphs}
      />
      <ProtectedRoute
        exact
        path="/admin/weekly-schedule"
        roles={admin}
        component={LoanWeekList}
      />
      <ProtectedRoute
        exact
        path="/admin"
        roles={collector}
        component={Dashboard}
      />
      <ProtectedRoute
        exact
        path="/admin/activity"
        roles={collector}
        component={Dashboard}
      />
      {/* <ProtectedRoute exact path='/admin/client/create' roles={collector} component={CreateClientForm} /> */}
      <ProtectedRoute
        exact
        path="/admin/client-create"
        roles={collector}
        component={OnboardingClient}
      />
      <ProtectedRoute
        exact
        path="/admin/loan/list"
        roles={collector}
        component={LoanList}
      />
      <ProtectedRoute
        exact
        path="/admin/loan/calculator"
        roles={collector}
        component={Calculator}
      />
      <ProtectedRoute
        exact
        path="/admin/collection-late"
        roles={collector}
        component={Collection}
      />
      <ProtectedRoute
        exact
        path="/admin/loan-collection"
        roles={collector}
        component={LoanCollection}
      />
      <ProtectedRoute
        exact
        path="/admin/payments"
        roles={collector}
        component={Payments}
      />
      <ProtectedRoute
        exact
        path="/admin/client-search"
        roles={collector}
        component={ClientList}
      />
      <ProtectedRoute
        path="/admin/investor/details/:investorId"
        roles={admin}
        component={DetailInvestor}
      />
      <ProtectedRoute
        path="/admin/cash-account/:cashAccount/:totalCash"
        roles={admin}
        component={CashAccounts}
      />
      <ProtectedRoute
        path="/admin/loan/:loanId"
        roles={collector}
        component={LoanDetails}
      />
      <ProtectedRoute
        path="/admin/client/:clientId"
        roles={collector}
        component={ClientDetails}
      />
      <ProtectedRoute
        path="/admin/create-loan/:clientId"
        roles={collector}
        component={CreateLoan}
      />
    </div>
  );
};

export default AdminRoutes;
