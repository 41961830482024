import React, { useState, useContext } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import Bar from "./Bar.jsx";
import NavBar from "./NavBar.jsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import './layout.scss'
import { withStyles } from "@material-ui/core/styles";
import authService from '../auth/AuthService'
import { authContext } from '../../contexts/AuthContext'

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    backgroundColor: '#F2F5F7',
    flexGrow: 1,
  }
});

const Layout = ({ history, ...props }) => {
  const { classes, children } = props;
  const { auth, setAuthData } = useContext(authContext);
  const [initialState, setInitialState] = useState({
    mobileOpen: false,
    name: "Actividad"
  })

  const logout = () => {
    authService.logout()
      .then((res) => {
        if (res.message === 'success') {
          setAuthData(null);
          history.push('/login')
        }
      })
  }

  const handleBarTitle = (name) => {
    setInitialState({ ...initialState, [name]: name })
  }
  const handleDrawerToggle = () => {
    setInitialState({ ...initialState, mobileOpen: !initialState.mobileOpen });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Bar BarTitle={initialState.name} user={auth.data} logout={logout} firstName={auth.data.firstName} id={(auth.data._id.substring(auth.data._id.length - 7, auth.data._id.length)).toUpperCase()} lastName={auth.data.lastName} handleDrawerToggle={handleDrawerToggle} />
      <NavBar
        user={auth.data}
        handleDrawerToggle={handleDrawerToggle}
        handleBarTitle={handleBarTitle}
        mobileOpen={initialState.mobileOpen}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {(auth.data.status !== 'VERIFIED' && !auth.data.admin && !auth.data.collector && <div className="account-status-notify"><p>Completa tu perfil para disfrutar de nuestro servicios</p></div>)}
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Layout));
