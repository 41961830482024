import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { CsvBuilder } from 'filefy'
import numbro from 'numbro'
import moment from 'moment'
import LoanService from '../../../../../services/LoanService'
import './LoanTransactions.scss'

const tableColumns = [
    {
        title: 'Id', field: '_id', render: rowData => rowData._id, hidden: true
    },
    {
        title: 'Investor Name', field: 'fullName', render: rowData => rowData.fullName, hidden: true
    },
    {
        title: 'Cuota Id', field: '_loanSchedule', hidden: true
    },
    {
        title: 'Prestamo Id', field: '_loan', render: rowData => rowData._loan, hidden: true
    },
    {
        title: 'Pago Id', field: '_payment', hidden: true
    },
    {
        title: 'Inversor', field: '_investor', render: rowData => <Link to={`/admin/investor/details/${rowData._investor}`}> {rowData.fullName}</Link >
    },
    {
        title: 'Fecha', field: 'date', render: rowData => moment(rowData.date).format('YYYY/MM/DD'), width: 'auto',
        headerStyle: {
            width: '50px',
        },
        defaultSort: 'desc',
        type: 'date'
    },
    { title: 'Cuenta', field: 'cashAccount', hidden: true },
    { title: 'Concepto', field: 'concept' },
    {
        title: 'Monto', field: 'amount', type: 'numeric', hidden: true, render: rowData => numbro(rowData.amount).format({
            thousandSeparated: true,
            mantissa: 2,
        })
    },
    {
        title: 'Debito', field: 'debit', type: 'numeric', render: rowData => numbro(rowData.debit).format({
            thousandSeparated: true,
            mantissa: 2,
        })
    },
    {
        title: 'Credito', field: 'credit', type: 'numeric', render: rowData => numbro(rowData.credit).format({
            thousandSeparated: true,
            mantissa: 2,
        })
    },
]

function TransactionTable(props) {
    const [tx, setTx] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const loanService = new LoanService()

    const handleExportCsv = (columns, renderData) => {
        const csvColumns = columns
            .filter(columnDef => {
                return columnDef.field && columnDef.export !== false;
            });

        const data = renderData.map(rowData =>
            csvColumns.map(columnDef => rowData[columnDef.field])
        );

        const builder = new CsvBuilder(`${props.loanId}-loan-tx-data.csv`)
            .setDelimeter(',')
            .setColumns(csvColumns.map(columnDef => columnDef.field))
            .addRows(data)
            .exportFile();
        return builder
    }

    function useGetLoanTxs(loanId) {
        const fetchData = useCallback(async () => {
            try {
                const res = await loanService.getLoanTransactions(loanId);
                if (res) {
                    setIsLoading(false)
                    setTx(res)
                }
            } catch (error) {
                console.log(error)
            }
        }, [loanId]);
        useEffect(() => {
            fetchData()
        }, [fetchData])
    }

    useGetLoanTxs(props.loanId)


    return (
        <div className='transactions-holder'>
            <MaterialTable
                columns={tableColumns}
                isLoading={isLoading}
                data={tx}
                title="Movimientos"
                options={{
                    columnsButton: true,
                    exportButton: true,
                    actionsColumnIndex: -1,
                    padding: 'dense',
                    exportCsv: handleExportCsv,
                    pageSize: 15,
                    pageSizeOptions: [15, 50, 100, 1000]
                }}
                toolbar={true}
            />
        </div>
    );
}



export default TransactionTable;